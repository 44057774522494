<script lang="ts">
export default defineComponent({
  props: {
    tag: {
      type: String,
      default: 'p',
    },
    text: {
      type: String,
      default: null,
    },
    className: {
      type: [String, Array],
      default: '',
    },
    hyperLinkClass: {
      type: [String, Array],
      default: '',
    },
  },
  setup(props) {
    const addHttps = (url: string) => {
      return url.startsWith('http://') || url.startsWith('https://')
        ? url
        : `https://${url.startsWith('www.') ? '' : 'www.'}${url}`
    }

    const urlRegex = /\b(?:https?:\/\/|www\.)\S*/gi

    const processText = (text: string) => {
      const parts = []
      let lastIndex = 0
      let match

      while ((match = urlRegex.exec(text)) !== null) {
        if (lastIndex < match.index) {
          parts.push(text.slice(lastIndex, match.index))
        }

        parts.push(h('a', {
          href: addHttps(match[0]),
          target: '_blank',
          class: [props.hyperLinkClass, 'underline text-primary break-all'],
          onClick: (event: MouseEvent) => {
            event.stopPropagation()
          },
        }, match[0]))
        lastIndex = urlRegex.lastIndex
      }

      if (lastIndex < text.length) {
        parts.push(text.slice(lastIndex))
      }

      return parts
    }

    const lines = computed(() => props.text ? props.text.split('\n') : [])

    const children = computed(() => lines.value.reduce((accumulator: (string | VNode)[], line, index) => {
      const lineElements = processText(line)

      if (index > 0) {
        accumulator.push(h('br'))
      }

      return accumulator.concat(lineElements)
    }, []))

    return () => h(
      props.tag,
      {
        class: [props.className, 'break-all'],
      },
      children.value,
    )
  },
})
</script>
