<template>
  <div
    :disabled="disabled"
    :class="[
      'relative w-full h-full',
      {
        'cursor-not-allowed': disabled,
      },
      textWrapperClass,
      triggerClass?.(isEditing),
    ]"
    :data-test="dataTest ?? 'text-field'"
    @click.exact="onEditMode"
  >
    <div
      :class="[
        'max-w-full leading-4 p-2',
        contentClass,
        textPreviewClass,
        {
          '!cursor-not-allowed': disabled,
          'px-1.5 py-0': preview,
          'pr-6': alertMessage
        },
      ]"
    >
      <span v-if="!internalValue" class="group-hover/cell:visible invisible">-</span>
      <template v-else-if="isNumber && internalValue">
        {{ formatNumber(internalValue) }}
      </template>
      <template v-else>
        <ProcessedText
          :text="internalValue" :class="textPreviewClass"
        />
      </template>
      <UTooltip
        v-if="alertMessage"
        class="absolute top-1/2 -translate-y-1/2 right-2 h-fit"
        :text="alertMessage"
        :popper="{
          placement: 'top',
          arrow: true,
        }"
      >
        <Icon
          name="heroicons:exclamation-triangle-20-solid"
          size="16"
          class="text-yellow-500"
        />
      </UTooltip>
    </div>
    <span
      v-if="isEditing && !preview"
      ref="editableSpan"
      :class="[
        'textarea',
        'outline-none !bg-white absolute top-0',
        'border-none ring-1 ring-primary-500',
        'leading-4 min-w-full min-h-full p-2',
        'z-10 overflow-y-auto max-w-full w-full',
        contentClass,
        textEditClass,
      ]"
      role="textbox"
      :contenteditable="!disabled"
      data-placeholder="-"
      @keydown.esc.stop="onChange"
      @keydown.enter.stop="onChange"
      @input="updateInternalValue"
      @focus="onFocus"
    >
      {{ internalValue }}
    </span>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, nextTick } from 'vue'
import { FieldType } from '#field/constant'
import type { TaskFieldRef } from '#field/types'
import { SprintMeasurement } from '~/layers/sprint/constant'

defineOptions({
  inheritAttrs: false,
})

const props = defineProps({
  taskId: {
    type: String,
    required: true,
  },
  value: {
    type: String,
    required: false,
  },
  preview: {
    type: Boolean,
    default: false,
  },
  fieldId: {
    type: String,
    required: true,
  },
  contentClass: {
    type: String,
    required: false,
  },
  textPreviewClass: {
    type: String,
    required: false,
  },
  textWrapperClass: {
    type: String,
    required: false,
  },
  textEditClass: {
    type: String,
    required: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  regex: {
    type: RegExp,
    required: false,
  },
  triggerClass: {
    type: Function as PropType<(active: boolean) => string[]>,
    required: false,
  },
  dataTest: {
    type: String,
  },
  isNumber: {
    type: Boolean,
    default: false,
  },
  alertMessage: {
    type: String,
    required: false,
    default: '',
  },
  measurement: {
    type: String,
    default: '',
  }
})

const emit = defineEmits<{
  (e: 'update:open', value: boolean): void
}>()

const internalValue = ref(props.value)
const isEditing = ref(false)
const editableSpan = ref<HTMLSpanElement | null>(null)

const { updateTaskCustomField, updateTasks } = useUpdateTask()

const updateInternalValue = (event: Event) => {
  const target = event.target as HTMLSpanElement
  const value = target.innerText
  if (!value.trim()) {
    target.innerText = ''
  }

  if (props.regex) {
    const match = value?.match(props.regex)
    const formattedValue = match ? match[0] : ''

    target.innerText = formattedValue
    onFocus()
  }

  internalValue.value = target.innerText
}

const onChange = () => {
  ;(editableSpan.value as HTMLSpanElement)?.blur()

  // Update the value
  if (
    internalValue.value !== props.value &&
    internalValue.value !== undefined
  ) {
    switch (props.fieldId) {
      case FieldType.ACTUAL_EFFORT: {
        const value = parseFloat(internalValue.value)
        if (isNaN(value)) {
          internalValue.value = props.value
          return
        }

        if (value < 0) {
          internalValue.value = '0'
        }

        if (props.measurement === SprintMeasurement.HOURS) {
          updateTasks([props.taskId], {
            actualHour: parseFloat(internalValue.value),
          }, true)
          break
        }

        updateTasks([props.taskId], {
          actualPoint: parseFloat(internalValue.value),
        }, true)
        break
      }

      case FieldType.ESTIMATED_EFFORT: {
        const value = parseFloat(internalValue.value)
        if (isNaN(value)) {
          internalValue.value = props.value
          return
        }

        if (value < 0) {
          internalValue.value = '0'
        }

        if (props.measurement === SprintMeasurement.HOURS) {
          updateTasks([props.taskId], {
            estimatedHour: parseFloat(internalValue.value),
          }, true)
          break
        }

        updateTasks([props.taskId], {
          estimatedPoint: parseFloat(internalValue.value),
        }, true)
        break
      }

      default:
        updateTaskCustomField({
          taskIds: [props.taskId],
          fieldId: props.fieldId,
          value: internalValue.value,
          useSelectedTasks: true,
        })
    }
  }

  emit('update:open', false)
  isEditing.value = false
}

onClickOutside(editableSpan, () => {
  onChange()
})

const onEditMode = () => {
  if (props.preview || props.disabled) return

  isEditing.value = true
  nextTick(() => {
    if (editableSpan.value) {
      editableSpan.value.focus()
    }
  })
  emit('update:open', true)
}

const onFocus = () => {
  if (editableSpan.value) {
    editableSpan.value.scrollTop = editableSpan.value?.scrollHeight
    const range = document.createRange()
    const selection = window.getSelection()
    selection?.removeAllRanges()
    range.selectNodeContents(editableSpan.value)
    range.collapse(false)
    selection?.addRange(range)
  }
}

defineExpose<TaskFieldRef>({
  open: onEditMode,
  close: () => {
    isEditing.value = false
  },
  delete: () => {
    internalValue.value = ''
    onChange()
  },
})

onMounted(() => {
  internalValue.value = props.value
})

watch(
  () => props.value,
  (val) => {
    internalValue.value = val
  }
)
</script>

<style scoped lang="scss">
.textarea[contenteditable]:empty::before {
  content: attr(data-placeholder);
  color: theme('colors.gray.500');
}
</style>
